<template>
    <transition name="drawer">
        <div id="J_feedback" class="feedback-group" :class="{'is-active': showDrawer}">
            <feedback-title
                :title="title"
                @handlerClickTitle="handlerClickTitle"
            >
                <template v-slot:back>
                    <back-icon v-if="showBack" @back="back"></back-icon>
                </template>
                <template v-slot:close>
                    <close-icon @close="close"></close-icon>
                </template>
            </feedback-title>
            <tab-group
                v-if="showTitle"
                :curName="curName"
                :tabs="tabs"
                :hasSendBackInfo="hasSendBackInfo"
                @tabHandlerClick="tabHandlerClick"
            >
            </tab-group>
            <component
                :is="curCom"
                :userRouters="userRouters"
                :submitFormData="submitFormData"
                @changeSelectModule="changeSelectModule"
                @starStatus="starStatus"
                @changeTextarea="changeTextarea"
                @setFeedPicture="setFeedPicture"
                @drawerChange="drawerChange"
                @changeContacts="changeContacts"
                @submit="submit"
                @close="close"

                :loadingFeedbackList="loadingFeedbackList"
                :feedbackListError="feedbackListError"
                :feedbackList="feedbackList"
                @goToDetail="goToDetail"
                @retry="retry"

                :detailInfo="detailInfo"

                @help="help"
                @checkList="checkList"
            >
            </component>
        </div>
    </transition>
</template>
<script>
import FeedbackForm from './FeedbackForm.vue';
import FeedbackList from './FeedbackList.vue';
import FeedbackDetail from './FeedbackDetail.vue';
import SuccessGroup from './components/SuccessGroup.vue';

import FeedbackTitle from './components/TitleGroup.vue';
import TabGroup from './components/TabGroup.vue';
import BackIcon from './components/BackIcon.vue';
import CloseIcon from './components/CloseGroup.vue';

import { saveFeedBack, getListByCondition } from './https/index.js'
export default {
    name: "Feedback",
    components: {
        FeedbackForm,
        FeedbackList,
        FeedbackDetail,
        FeedbackTitle,
        TabGroup,
        CloseIcon,
        BackIcon,
        SuccessGroup
    },
    props: {
        projectId: String,
        feedType: String,
        schoolName: String,
        userName: String,
        userRouters: Array,
        originRouters: Array,
        userId: String
    },
    data () {
        return {
            compList: [
                FeedbackForm,
                FeedbackList,
                FeedbackDetail,
                SuccessGroup
            ],
            idx: 0,

            curName: 'onlineFeedback',
            tabs: [
                {
                    id: 'onlineFeedback',
                    title: '我要反馈'
                },
                {
                    id: 'feedbackRecord',
                    title: '反馈记录'
                }
            ],

            showDrawer: false,
            // 'https://xkfw.xinkaoyun.com:40060/prod-api/feed'
            agencyApi: process.env.VUE_APP_XKSERVER_FEED_AGENCY_API, // 接口请求地址

            submitFormData: {
                projectId: '', // cloud_campus（智慧校园）
                projectName: '',
                feedType: '0', // 0 用户主动型， 1 平台主动型
                resourceId: '', // 功能流程资源（菜单名称）
                resourceName: '', // 功能流程资源（菜单名称）
                experienceRating: {
                    completeFunctionality: '0',
                    qualityOperation: '0',
                    simpleOperation: '0',
                    beautifulInterface: '0',
                    serviceQuality: '0'
                }, // 评星
                feedDesc: '', // 具体描述
                feedPicture: '', // 相关图片
                userName: '', // 用户名
                phone: '', // 联系方式
                school: '', // 学校名称
                systemVersion: '1', // 系统版本
                remark: '', // 备注
                userPhone: '', // 用户信息联系方式
                userId: '',
                terminalType: '6'
            },
            feedback: {
                userId: '',
                terminalType: ''
            },
            feedbackList: [],
            loadingFeedbackList: true,
            feedbackListError: false,
            detailInfo: {},
        }
    },
    computed: {
        title () {
            switch (this.idx) {
                case 0:
                    return '意见反馈';
                case 1:
                    return '反馈记录';
                case 2:
                    return '反馈详情';
                case 3:
                    return '意见反馈';
                default:
                    return '意见反馈';
            }
        },
        showBack () {
            switch (this.idx) {
                case 0:
                case 1:
                case 3:
                    return false;
                case 2:
                    return true;
                default:
                    return false;
            }
        },
        showTitle () {
            switch (this.idx) {
                case 0:
                case 1:
                    return true;
                case 3:
                case 2:
                    return false;
                default:
                    return true;
            }
        },
        curCom () {
            return this.compList[this.idx];
        },
        hasSendBackInfo() {
            return this.feedbackList.some(item => item.feedStatus == '2' && item.isRead == '0');
        }
    },
    created () {
        this.init();
    },
    methods: {
        init () {
            let _this = this;
            this.initPageData();
            this.openDrawers();
            this.getFeedbackList();
        },
        initPageData () {
            this.submitFormData.projectId = this.projectId;
            this.submitFormData.userName = this.userName;
            this.submitFormData.school = this.schoolName;
            this.submitFormData.feedType = this.feedType || '0';
            this.submitFormData.userId = this.userId;
            this.feedback.userId = this.userId;
        },
        openDrawers () {
            let _this = this;
            setTimeout(function(){
                console.log('输出1')
                _this.showDrawer = !_this.showDrawer;
            },100);
        },
        getFeedbackList () {
            let _this = this;
            this.loadingFeedbackList = true;
            getListByCondition(this.feedback, this.agencyApi)
                .then((res) => {
                    if (res.code === '200') {
                        this.feedbackList = res.data.list ? res.data.list : [];
                        this.feedbackListError = false;
                    } else {
                        this.feedbackListError = true;
                    }
                    setTimeout(function(){
                        _this.loadingFeedbackList = false;
                    }, 200)
                })
                .catch(err => {
                    console.log(err,'err-err')
                    this.loadingFeedbackList = false;
                    this.feedbackListError = true;
                })
        },
        /**
         * @Description: tabs切换
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-12-27 15:04:21
         * @param {*} idx
         */
        tabHandlerClick (idx) {
            switch (idx) {
                case '0':
                    this.resetFormData ();
                    this.idx = 0;
                    break;
                case '1':
                    this.idx = 1;
                    this.getFeedbackList()
                    break;
                default:
                    break;
            }
        },
        /**
         * @Description: 抛出星数量
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-12-04 14:22:24
         * @param {*} num
         * completeFunctionality  功能完整
         * qualityOperation       运行质量
         * simpleOperation        操作简单
         * beautifulInterface     界面美观
         * serviceQuality         服务质量
         */
        starStatus (args) {
            const [ tar, starNum] = args;
            switch (tar) {
                case 'completeFunctionality':
                    this.submitFormData.experienceRating.completeFunctionality = starNum;
                    break;
                case 'qualityOperation':
                    this.submitFormData.experienceRating.qualityOperation = starNum;
                    break;
                case 'simpleOperation':
                    this.submitFormData.experienceRating.simpleOperation = starNum;
                    break;
                case 'beautifulInterface':
                    this.submitFormData.experienceRating.beautifulInterface = starNum;
                    break;
                case 'serviceQuality':
                    this.submitFormData.experienceRating.serviceQuality = starNum;
                    break;
                default:
                    break;

            };
        },

        /**
         * @Description: 抛出模块名称
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-12-04 14:23:02
         */
        changeSelectModule (value) {
            let names = this.getModuleName(this.originRouters, value);

            this.submitFormData.resourceId = names.join('/');
            this.submitFormData.resourceName = names.join('/');
            console.log(this.submitFormData.resourceId, this.submitFormData.resourceName)
        },
        getModuleName (originRouters, ids) {
            let ar1 = [];
            originRouters.map(item => {
                ids.map(subItem => {
                    if (item.menuId == subItem) {
                        ar1.push(item.title)
                    }
                })
            });

            return ar1;
        },
        /**
         * @Description: 抛出意见建议
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-12-04 14:26:47
         * @param {*} value
         */
        changeTextarea (value) {
            this.submitFormData.feedDesc = value;
        },
        drawerChange () {
            this.$emit('changeToolbarStatus')
            this.openDrawers();
        },
        /**
         * @Description: 抛出图片
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-12-14 10:48:52
         */
        setFeedPicture (arr) {
            const imgList = arr.map(item => {
                return item.url
            }).join(',');

            this.submitFormData.feedPicture = imgList;
        },
        /**
         * @Description: 抛出联系方式
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-12-04 14:27:48
         */
        changeContacts (value) {
            this.submitFormData.phone = value;
            this.submitFormData.userPhone = value;
        },
        submit () {
            let _this = this;
            let submitFormData = JSON.parse(JSON.stringify(this.submitFormData));
            submitFormData.experienceRating = JSON.stringify(submitFormData.experienceRating);
            console.log(this.submitFormData.resourceId,'this.submitFormData.resourceId')
            if (!this.submitFormData.resourceId) {
                return this.$message.info('请选择您在使用哪个功能模块遇到了问题~')
            };
            console.log(submitFormData,'submitFormData最终值')
            saveFeedBack(submitFormData, this.agencyApi)
            .then((res) => {
                if (res.code == '200') {
                    this.submitFormData.resourceId = '';
                    this.submitFormData.resourceName = '';
                    setTimeout(function(){
                       _this.idx = 3;
                       _this.getFeedbackList ();
                    }, 200)
                } else if (res.msg){
                    this.$message.error(res.msg)
                } else {
                    this.$message.error('反馈失败，请重新再试！')
                };

            }).catch((err) => {
                if (err.msg){
                    this.$message.error(err.msg)
                } else {
                    this.$message.error('反馈失败，请重新再试！')
                }
            })
        },
        close () {
            const oFeedback = document.querySelector('#J_feedback');
            document.body.removeChild(oFeedback);
        },
        goToDetail (info) {
            this.detailInfo = info;
            this.idx = 2;
        },
        retry () {
            this.getFeedbackList ();
        },
        back () {
            this.curName = 'feedbackRecord';
            this.idx = 1;
            this.getFeedbackList ();
        },
        help () {
            this.resetFormData ();
            this.curName = 'onlineFeedback';
            this.idx = 0;
        },
        resetFormData () {
            this.submitFormData.experienceRating.beautifulInterface = 0;
            this.submitFormData.experienceRating.completeFunctionality = 0;
            this.submitFormData.experienceRating.qualityOperation = 0;
            this.submitFormData.experienceRating.serviceQuality = 0;
            this.submitFormData.experienceRating.simpleOperation = 0;
            this.submitFormData.feedDesc  = '';
            this.submitFormData.phone = '';
            this.submitFormData.userPhone = '';
            this.submitFormData.feedPicture = '';
        },
        checkList () {
            this.curName = 'feedbackRecord';
            this.idx = 1;
            this.getFeedbackList ();
        },
        handlerClickTitle () {
            if (this.showBack) {
                this.back();
            }
        },
        load () {}
    },
    watch: {
        submitFormData: {
            handler (newVal) {
                console.log(this.submitFormData,'submitFormData值变化')
            },
            deep: true
        },
    }
}
</script>
<style lang="scss" scoped>
    .feedback-group {
        width: 440px;
        height: 100%;
        position: fixed;
        right: 0px;
        top: 0px;
        bottom: 0px;
        background: #FFFFFF;
        box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.39);
        z-index: 3000;
        box-sizing: border-box;
        transform: translateX(100%);
        transition: all 0.3s ease-out;
        .btn-group {
            padding-top: 24px;
            /deep/ button {
                border: none;
            }
            .submit {
                width: 145px;
                height: 36px;
                background: #1990FF;
                border-radius: 2px;
            }
            .close {
                width: 145px;
                height: 36px;
                background: #FFFFFF;
                border-radius: 2px;
            }
        }
    }

    .feedback-group.is-active {
        transform: translateX(0);
    }

</style>
